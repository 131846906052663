import { Global } from '~/global'
import { OWAuthDataModel } from '~/models/OWAuthDataModel'
import { hasFeature } from './OWUtils'
import { Features } from './Constants'
import { AdminUtils } from '~/utils/AdminUtils'
import { OWReactPageRouter } from '~/app/OWReactPageRouter'
import { ServerApi } from '~/utils/ServerApi'
import { getQueryString } from '~/utils/urlUtils'
import { HoverBadgeController } from '~/components/HoverBadgeController'
import { PageLoadTimeModel } from '~/models/PageLoadTimeModel'
import * as EmployeeUtils from '~/utils/EmployeeUtils'
import { LocationUtils } from '~/utils/LocationUtils'
import { ADMIN_COMPANY_PAGE_TABS } from '~/react/tabs'
import ReactGA from 'react-ga'
import { NavigateFunction } from 'react-router-dom'
import { Id, Maybe } from '~/app/types'

let _instance

/**
 * Gets the url for a groups module page
 * @param {string} [slug_name] The group slug
 * @param {string} [public_id] The public id of the employee
 * @param {string} [v] The view flag
 * @param {string} [m] The mode flag
 * @param {string} [f] The former view flag
 * @param {Boolean} [flat] The flat list flag
 * @param {Boolean} [isInactive] The isInactive list members view flag
 * @param {Boolean} [isContractor] The isContractor list members view flag
 * @param {Boolean} [isExternal] The isExternal list members view flag
 * @param {number} [page] The page number
 * @param {string[]} [ordering] The sort ordering
 * @param {string} [search] The query string
 * @param {Boolean} [fromInstantSearch] True if we are navigating from instant search
 * @param {string} [req] The requestor employee public id
 * @returns {string} The url
 */
export const urlForGroups = ({
    slug_name,
    public_id,
    v,
    m,
    f,
    flat,
    isInactive,
    isContractor,
    isExternal,
    page,
    ordering,
    search,
    fromInstantSearch,
    req,
}: {
    slug_name?: string
    public_id?: string
    v?: string
    m?: string
    f?: string
    flat?: boolean
    isInactive?: boolean
    isContractor?: boolean
    isExternal?: boolean
    page?: number
    ordering?: string[]
    search?: string
    fromInstantSearch?: boolean
    req?: string
}): string => {
    const params = {
        ordering: (ordering || []).join(','),
        flat: flat ? '1' : null,
    }
    const fis = fromInstantSearch ? '1' : null
    if (public_id) {
        return `/employees/${public_id}/dlists/${getQueryString({
            ...params,
            v,
            m,
            f,
            page,
            search,
        })}`
    } else if (slug_name) {
        return `/groups/${slug_name}/${getQueryString({
            ...params,
            v,
            m,
            f,
            isInactive: isInactive ? '1' : null,
            isContractor: isContractor ? '1' : null,
            isExternal: isExternal ? '1' : null,
            page,
            search,
            fis,
            req,
        })}`
    } else {
        return `/groups/${getQueryString({
            ...params,
            v,
            m,
            f,
            page,
            search,
            fis,
        })}`
    }
}

/**
 * Gets the url for a locations module page
 */
export const urlForLocations = ({
    slug,
    v,
    f,
    page,
    ordering,
    search,
    fps,
    fromInstantSearch,
    setTrue,
    conferenceRoomSlug,
    secondary,
}: {
    slug?: string
    v?: string
    f?: string
    page?: number
    ordering?: string[]
    search?: string
    fps?: Maybe<string>
    fromInstantSearch?: boolean
    setTrue?: boolean
    conferenceRoomSlug?: string
    secondary?: boolean
}): string => {
    const ordering_string = (ordering || []).join(',')
    const fis = fromInstantSearch ? '1' : null
    const st = setTrue ? '1' : null
    const se = secondary === undefined ? undefined : secondary ? '1' : '0'
    if (slug) {
        const base =
            conferenceRoomSlug === undefined
                ? `/offices/${slug}`
                : conferenceRoomSlug
                  ? `/offices/${slug}/conference_rooms/${conferenceRoomSlug}`
                  : `/offices/${slug}/conference_rooms`
        return `${base}/${getQueryString({
            v,
            f,
            page,
            ordering: ordering_string,
            search,
            fps,
            fis,
            st,
            se,
        })}`
    } else {
        return `/offices/${getQueryString({
            v,
            f,
            page,
            ordering,
            search,
            fps,
            fis,
        })}`
    }
}

/**
 * Gets the url for the admin company page
 * @param {String} [v] The tab arg
 * @param {Number} [page] The page in a paginated list
 * @param {String[]} [ordering] The sort order in a paginated list
 */
export const urlForAdminCompany = ({
    v,
    page,
    ordering: orderA,
}: {
    v?: string
    page?: number
    ordering?: string[]
}) => {
    // Force default v to prelaunch to ensure prelaunch tab doesn't nav on launch
    if (hasFeature(Features.PRELAUNCH_MODE)) {
        v = v || ADMIN_COMPANY_PAGE_TABS.prelaunch
    }
    const ordering = (orderA || []).join(',')
    return `/admin/company/${getQueryString({
        v,
        page,
        ordering,
    })}`
}

type UrlForSearchProps = {
    q?: string // The full search query
    page?: number // The page of results
    sks?: string // The Skill Slug for a pre-loaded search
    skn?: string //The Skill Name for a pre-loaded search
    an?: string // The Area Name for a pre-loaded search
    pn?: string // The Product Name for a pre-loaded search
    tp?: number // The Transfer Portal state for a pre-loaded search
    f?: string // The filter hash
    fromInstantSearch?: boolean // True if we are navigating from instant search
    mv?: boolean // Whether to show the search map view
    mc?: any[] // If present, center the map at this location
    mz?: number // If present, use this as the initial map zoom level
    city?: number // If present, use this as the requested city id
    cityname?: string // If present, use this as the requested city full name
}
/**
 * Gets the url for an advanced search module page
 */
export const urlForSearch = ({
    q,
    page,
    sks,
    skn,
    an,
    pn,
    tp,
    f,
    fromInstantSearch,
    mv,
    mc,
    mz,
    city,
    cityname,
}: UrlForSearchProps) => {
    const fis = fromInstantSearch ? '1' : null
    return `/search/${getQueryString({ q, page, sks, skn, an, pn, tp, f, fis, mv, mc, mz, city, cityname })}`
}

/**
 * Gets the url for the homepage
 * @returns {string} The url
 */
export const urlForHome = () => {
    if (hasFeature(Features.SHOW_DLISTS_HOMEPAGE)) {
        return '/groups/?v=m'
    }
    return '/'
}

/**
 * Gets the url for the newsfeed
 */
export const urlForNewsFeed = () => '/feed/'

/**
 * Gets the url for a single tle
 */
export const urlForSingleTLE = ({ tleId }: { tleId: Id }) => `/social/${tleId}/`

/**
 * Gets the url for an employee
 */
type UrlForEmployeeParams = {
    public_id?: string // The public id of the employee
    edit?: boolean //  If true, get the url for the edit page
    fromInstantSearch?: boolean //  True if we are navigating from instant search
    onboarding?: boolean //  If true, also show the onboarding overlay
    ordering?: string //  The sort ordering for the dlists card
    page?: number //  The page for the dlists card
    search?: string //  The search query for the dlists card
}
export const urlForEmployee = ({
    public_id,
    edit,
    fromInstantSearch,
    onboarding,
    ordering,
    page,
    search,
}: UrlForEmployeeParams): string => {
    if (!public_id) {
        public_id = Global.ROOT_PUBLIC_ID
    }
    const query = getQueryString({
        ...(fromInstantSearch && { fis: '1' }),
        ...(onboarding && { on: '1' }),
        ...(ordering && { ordering }),
        ...(page && { page }),
        ...(search && { search }),
    })
    return `/employees/${public_id}/${edit ? 'edit/' : ''}${query}`
}

/**
 * Gets the url for a onboarding module page
 */
export const urlForOnboarding = ({ step }) => `/my-profile/${step}/`

/**
 * Gets the url for the admin users page
 */
export const urlForAdminUsers = ({
    v,
    edit,
    pk,
    page,
    search,
    ordering,
}: {
    v?: string // The tab arg
    edit?: boolean // True iff we are in edit mode
    pk?: string //The pk of the user to edit
    page?: number // the page in a paginated list
    search?: string //the search query
    ordering?: string[] //the sort order in a paginated list
}) => {
    const orderingString = (ordering || []).join(',')
    const queryString = getQueryString({
        v,
        pk,
        ...(edit && { edit: 1 }),
        ...(orderingString && { orderingString }),
        search,
        page,
    })
    return `/admin/users/${queryString}`
}

/**
 * Gets the url for the admin features page
 */
export const urlForAdminFeatures = ({ v }: { v?: string }) => {
    return `/admin/features/${getQueryString({ v })}`
}

/**
 * Gets the url for the admin reports page
 */
export const urlForAdminReports = () => {
    return `/admin/reports/${getQueryString({})}`
}

/**
 * Gets the url for an in-memoriam page
 */
export const urlForInMemoriam = ({ publicId, edit = false }: { publicId: string; edit?: boolean }) => {
    return `/in-memoriam/${publicId}/` + (edit ? 'edit/' : '')
}

/**
 * Gets the url for the enter-a-domain page
 */
export const urlForEnterADomain = () => {
    return `${Global.MAIN_URL}/login/`
}

/**
 * Gets the url for the domain recovery page
 */
export const urlForDomainRecovery = () => {
    return `${Global.MAIN_URL}/recover/`
}

/**
 * Gets the url for the domain list page
 */
export const urlForDomainList = () => {
    return `${Global.MAIN_URL}/domain_list/`
}

export class PlaceManager {
    router: OWReactPageRouter
    navbar: any
    forceRefresh: boolean
    scrollChecker: any
    scrollMaster: any
    stateMaster: number
    private navigate?: NavigateFunction

    static getInstance() {
        return _instance
    }

    static setInstance(instance) {
        _instance = instance
    }

    setNavigate(navigate) {
        this.navigate = navigate
    }

    constructor(app) {
        this.router = new OWReactPageRouter(app)

        // ref to the navbar component
        this.navbar = null

        // If true, refresh on next navigation
        this.forceRefresh = false

        // Id of setTimeout for cleaning up scroll style after popstate
        this.scrollChecker = null

        // Dict of state id to scrollTop
        this.scrollMaster = {}

        // Incrementing state id
        this.stateMaster = history.length

        // On popstate, handle navigation to new state, and get old sroll position
        globalThis.addEventListener('popstate', e => {
            // Clear any pending scroll checking
            if (this.scrollChecker) {
                globalThis.clearTimeout(this.scrollChecker)
            }

            // Update the document loadTime
            PageLoadTimeModel.getInstance().setPageLoad()

            const state = e.state || {}
            const { id } = state
            const scrollTop = id ? this.scrollMaster[id] : undefined
            this.router.routeCurrentUrl()

            // Artificially expand new page to match recorded scroll position
            if (scrollTop || scrollTop === 0) {
                document.documentElement.setAttribute('style', `height: ${scrollTop + window.innerHeight}px`)
                document.documentElement.scrollTop = scrollTop

                // After 1 cycle, any new loading requests will be in flight - wait for them all to complete, and
                // remove page expansion
                this.scrollChecker = globalThis.setTimeout(() => {
                    const checkFlight = () => {
                        if (ServerApi.requestCount === 0) {
                            document.documentElement.setAttribute('style', '')
                        } else {
                            this.scrollChecker = globalThis.setTimeout(() => checkFlight(), 50)
                        }
                    }
                    checkFlight()
                }, 0)
            }
        })

        // On scroll, store current scroll position
        window.addEventListener('scroll', () => {
            const id = (history.state || {}).id
            if (id) {
                this.scrollMaster[id] = document.documentElement.scrollTop
            }
        })

        // Force a refresh in 15 minutes
        globalThis.setTimeout(() => this.forceRefreshOnNav(), 1000 * 60 * 15)
    }

    forceRefreshOnNav() {
        this.forceRefresh = true
    }

    static canSpaNavFromHere() {
        return Global.CAN_SPA
    }

    _updateLocation(href, title, args, replace, noScroll = false) {
        // Force a page refresh if needed
        if (this.forceRefresh || !PlaceManager.canSpaNavFromHere()) {
            globalThis.location = href
            return
        }

        // Clear @mention badges
        new HoverBadgeController().removeAllBadges()

        this.stateMaster += 1
        const state = { href, title, args, id: this.stateMaster }
        // if (replace) {
        //     history.replaceState(state, title, href);
        // } else {
        //     history.pushState(state, title, href);
        // }

        // Log page view to Google Analytics
        ReactGA.set({ title })
        ReactGA.pageview(href)

        // Update the document loadTime
        PageLoadTimeModel.getInstance().setPageLoad()
        if (this.navigate) this.navigate(href, { replace, state })

        // Refresh navbar (for the non-true-spa cases)
        if (this.navbar) {
            this.navbar.forceUpdate()
        }
        if (!noScroll) {
            document.documentElement.scrollTop = 0
        }
        return true
    }

    /**
     * Navigates to the homepage
     * @param {Boolean} [spa] If true, single page
     * @param {Boolean} [replace] If true, replace the history state
     */
    goToHome(spa = false, replace = false) {
        const href = urlForHome()
        if (spa && this.navigate) {
            this.navigate(href, { replace })
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to the newsfeed
     * @param {Boolean} [spa] If true, single page
     * @param {Boolean} [replace] If true, replace the history state
     */
    goToNewsFeed(spa = false, replace = false) {
        const href = urlForNewsFeed()
        if (hasFeature(Features.HIDE_NEWS_FEED) && !OWAuthDataModel.isActiveAdmin()) {
            globalThis.location.replace('/404')
            return
        }
        if (spa) {
            return this._updateLocation(href, 'News Feed | OrgWiki', {}, replace, false)
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to a single timeline entry
     * @param {Number} tleId The id of the tle
     * @param {Boolean} [spa] If true, do a single page navigation
     * @param {Boolean} [replace] If true, replace the history state when navigating
     * @returns {Boolean} True iff we single page navigated
     */
    goToSingleTLE({ tleId }, spa = false, replace = false) {
        const href = urlForSingleTLE({ tleId })
        if (hasFeature(Features.HIDE_NEWS_FEED) && !OWAuthDataModel.isActiveAdmin()) {
            globalThis.location.replace('/404')
            return
        }
        if (spa) {
            return this._updateLocation(href, 'View Post | OrgWiki', {}, replace, false)
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to the profile page for an employee
     * @param public_id The id of the employee to navigate to
     * @param full_name The full name of the employee to navigate to
     * @param [edit] If true, navigate to the employee edit page
     * @param [fromInstantSearch] True if we are navigating from instant search
     * @param [onboarding] If true, also show the onboarding overlay
     * @param [ordering] The sort ordering for the dlists card
     * @param [page] The page for the dlists card
     * @param [search] The search query for the dlists card
     * @param [spa] If true, perform a single page app transition
     * @param [replace] If true, replace the page state in history
     * @param [noScroll] If true, do not scroll to the top of the page
     */
    goToEmployee(
        {
            public_id,
            full_name,
            edit,
            fromInstantSearch,
            onboarding,
            ordering,
            page,
            search,
        }: {
            public_id: string
            full_name?: string
            edit?: boolean
            fromInstantSearch?: boolean
            onboarding?: boolean
            ordering?: string
            page?: number
            search?: string
        },
        spa = false,
        replace = false,
        noScroll = false
    ) {
        if (!public_id) {
            public_id = Global.ROOT_PUBLIC_ID
            full_name = Global.ROOT_FULL_NAME
        }
        const href = urlForEmployee({
            public_id,
            fromInstantSearch,
            edit,
            onboarding,
            ordering,
            page,
            search,
        })
        if (spa && PlaceManager.canSpaNavFromHere()) {
            return this._updateLocation(
                href,
                EmployeeUtils.getPageTitle({
                    name: full_name,
                    edit,
                }),
                {},
                replace,
                noScroll
            )
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to a groups module page
     * @param {string} [name] If present, the name of the list we are navigating to
     * @param {string} [slug_name] If present, the group to view
     * @param {string} [public_id] If present, the employee to view
     * @param {string} [v] If present, the tab arg
     * @param {string} [m] If present, the mode arg
     * @param {string} [f] If present, the list of lists flag navigated from
     * @param {Boolean} [flat] If present, flatten lists of members
     * @param {Boolean} [isInactive] If present, filter list of members to inactive employees
     * @param {Boolean} [isContractor] If present, filter list of members to contractors
     * @param {Boolean} [isExternal] If present, filter list of members to external emails
     * @param {number} [page] The page number
     * @param {string[]} [ordering] The sort ordering
     * @param {string} [search] The query string
     * @param {Boolean} [fromInstantSearch] True if we are navigating from instant search
     * @param {string} [req] The requestor employee public id
     * @param {Boolean} [spa] If true, try to single page transition
     * @param {Boolean} [replace] If true and spa, replace state instead of pushing state
     */
    goToGroups(
        {
            slug_name,
            public_id,
            v,
            m,
            f,
            flat,
            isInactive,
            isContractor,
            isExternal,
            page,
            ordering,
            search,
            fromInstantSearch,
            req,
        }: {
            slug_name?: string
            public_id?: string
            v?: string
            m?: string
            f?: string
            flat?: boolean
            isInactive?: boolean
            isContractor?: boolean
            isExternal?: boolean
            name?: string
            isDlist?: boolean
            page?: number
            ordering?: string[]
            search?: string
            fromInstantSearch?: boolean
            req?: string
        },
        replace = false
    ) {
        const href = urlForGroups({
            slug_name,
            public_id,
            v,
            m,
            f,
            flat,
            isContractor,
            isExternal,
            isInactive,
            page,
            ordering,
            search,
            fromInstantSearch,
            req,
        })
        if (this.navigate) this.navigate(href, { replace })
    }

    /**
     * Navigates to a locations module page
     * @param {string} [slug] If present, the group to view
     * @param {string} [v] If present, the tab arg
     * @param {string} [f] If present, the list of lists flag navigated from
     * @param {string} [name] If present, the name of the location we are navigating to
     * @param {number} [page] The page number
     * @param {string[]} [ordering] The sort ordering
     * @param {string} [search] The query string
     * @param {string} [fps] The floor plan slug of the viewed floor plan
     * @param {Boolean} [fromInstantSearch] True if we are navigating from instant search
     * @param {Boolean} [setTrue] True if we want to set the is_active flag true when arriving at the edit page
     * @param {string} [conferenceRoomSlug] The slug of the conference room we want to view
     * @param {string} [conferenceRoomName] The name of the conference room we want to view
     * @param {Boolean} [secondary] If true, viewing secondary location members
     * @param {Boolean} [spa] If true, try to single page transition
     * @param {Boolean} [replace] If true and spa, replace state instead of pushing state
     */
    goToLocations(
        {
            slug,
            v,
            f,
            name,
            page,
            ordering,
            search,
            fps,
            fromInstantSearch,
            setTrue,
            conferenceRoomSlug,
            conferenceRoomName,
            secondary,
        }: {
            slug?: string
            v?: string
            f?: string
            name?: string
            page?: number
            ordering?: string[]
            search?: string
            fps?: string
            fromInstantSearch?: boolean
            setTrue?: boolean
            conferenceRoomSlug?: string
            conferenceRoomName?: string
            secondary?: boolean
        },
        spa?: boolean,
        replace?: boolean
    ) {
        const href = urlForLocations({
            slug,
            v,
            f,
            page,
            ordering,
            search,
            fps,
            fromInstantSearch,
            setTrue,
            conferenceRoomSlug,
            secondary,
        })
        if (spa && PlaceManager.canSpaNavFromHere()) {
            return this._updateLocation(
                href,
                LocationUtils.getPageTitle({
                    name,
                    v,
                    conferenceRoomSlug,
                    conferenceRoomName,
                }),
                {},
                replace
            )
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to a search results module page
     * @param {string} [q] The full search query
     * @param {Number} [page] The page of results
     * @param {string} [sks] The Skill Slug for a pre-loaded search
     * @param {string} [skn] The Skill Name for a pre-loaded search
     * @param {string} [an] The Area Name for a pre-loaded search
     * @param {string} [pn] The Product Name for a pre-loaded search
     * @param {Boolean} [tp] The Transfer Portal state for a pre-loaded search
     * @param {string} [f] The filter hash
     * @param {Boolean} [fromInstantSearch] True if we are navigating from instant search
     * @param {Boolean} [mv] Whether to show the search map view
     * @param {Array} [mc] If present, center the map at this location
     * @param {Number} [mz] If present, use this as the initial map zoom level
     * @param {Number} [city] If present, use this as the requested city id
     * @param {string} [cityname] If present, use this as the requested city full name
     * @param {Boolean} [spa] If true, try to single page transition
     * @param {Boolean} [replace] If true and spa, replace state instead of pushing state
     */
    goToSearch({ q, page, sks, skn, an, pn, tp, f, fromInstantSearch, mv, mc, mz, city, cityname }, spa, replace) {
        const href = urlForSearch({
            q,
            page,
            sks,
            skn,
            an,
            pn,
            tp,
            f,
            fromInstantSearch,
            mv,
            mc,
            mz,
            city,
            cityname,
        })
        if (spa) {
            return this._updateLocation(href, 'Search Results | OrgWiki', {}, replace)
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to an onboarding module page
     * @param {string} [step] The step to navigate to
     * @param {Boolean} [spa] If true, single-page-navigate
     * @param {Boolean} [replace] If true and spa, replace state instead of pushing state
     */
    goToOnboarding({ step }, spa = false, replace = false) {
        const href = urlForOnboarding({ step })
        if (spa) {
            return this._updateLocation(href, 'Welcome | OrgWiki', {}, replace)
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to the admin users page
     * @param {String} [v] The tab arg
     * @param {Boolean} [edit] True iff we are in edit mode
     * @param {String} [pk] The pk of the user to edit
     * @param {Number} [page] The page in a paginated list
     * @param {String} [search] The search query
     * @param {String[]} [ordering] The sort order in a paginated list
     * @param {Boolean} [spa] If true, single-page-navigate
     * @param {Boolean} [replace] If true and spa, replace state instead of pushing state
     */
    goToAdminUsers(
        {
            v,
            edit,
            pk,
            page,
            search,
            ordering,
        }: {
            v: string
            edit?: boolean
            pk?: string
            page?: number
            search?: string
            ordering?: string[]
        },
        spa = false,
        replace = false
    ) {
        const href = urlForAdminUsers({
            v,
            edit,
            pk,
            page,
            search,
            ordering,
        })
        if (spa) {
            return this._updateLocation(href, AdminUtils.getAdminUsersTitle(), {}, replace)
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to the admin company page
     * @param {String} [v] The tab arg
     * @param {Number} [page] The page in a paginated list
     * @param {String[]} [ordering] The sort order in a paginated list
     * @param {Boolean} [spa] If true, single-page-navigate
     * @param {Boolean} [replace] If true and spa, replace state instead of pushing state
     */
    goToAdminCompany({ v, page, ordering }, spa = false, replace?: boolean) {
        const href = urlForAdminCompany({ v, page, ordering })
        if (spa) {
            return this._updateLocation(href, AdminUtils.getAdminCompanyTitle(), {}, replace)
        } else {
            globalThis.location.href = href
        }
    }

    /**
     * Navigates to an in-memoriam page
     * @param {String} publicId The public_id of the in-memoriam object
     * @param {String} name The full name of the in-memoriam person
     * @param {Boolean} [edit] If true, go to the edit page
     * @param {Boolean} [spa] If true, single-page navigate
     * @param {Boolean} [replace] If true and spa, replace state instead of pushing state
     */
    goToInMemoriam({ publicId, name, edit = false }, spa = false, replace = false) {
        const href = urlForInMemoriam({ publicId, edit })
        if (spa) {
            return this._updateLocation(href, `${name}, In Memoriam`, {}, replace)
        } else {
            globalThis.location.href = href
        }
    }
}
